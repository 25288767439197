<template>
  <div>
    <div
      class="desktop for-test-desktop"
      data-jest="desktop"
    >
      <a
        v-for="item in list"
        :key="item.id"
        class="item"
        data-jest="desktop-item"
        :href="item.value"
        :class="{ 'item_active for-test-item_active': item.id === activeItemId }"
        @click="onClickItem(item.value)"
        v-text="item.name"
      />
    </div>

    <div class="mobile for-test-mobile">
      <button
        class="item"
        data-jest="mobile-item"
        @click="openActionSheet"
        v-text="activeItemName"
      />

      <div
        v-if="isOpenActionSheet"
        class="overlay for-test-overlay"
        @click="closeActionSheet"
      />

      <div
        class="action-sheet for-test-action-sheet"
        :class="{ 'action-sheet_open for-test-action-sheet_open': isOpenActionSheet }"
        @click="closeActionSheet"
      >
        <div
          class="container"
          @click.stop="NOOP"
        >
          <p
            class="action-sheet__title for-test-action-sheet__title"
            v-text="title"
          />
          <div class="action-sheet__list">
            <button
              v-for="item in list"
              :key="item.id"
              class="action-sheet__item"
              data-jest="action-sheet-item"
              :class="{ 'action-sheet__item_active': item.id === activeItemId }"
              @click="
                closeActionSheet();
                onClickItem(item.value);
              "
            >
              {{ item.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import type { PropOptions } from 'vue';

  import PageScroll from '~/libs/PageScroll';
  import { NOOP } from '~/utils';

  export default defineNuxtComponent({
    name: 'TypeSwitch',

    props: {
      list: {
        type: Array,
        required: true,
      } as PropOptions<Array<TypeListItem>>,

      title: {
        type: String,
        required: true,
      } as PropOptions<string>,
    },

    data: (): {
      isOpenActionSheet: boolean;
    } => ({
      isOpenActionSheet: false,
    }),

    computed: {
      routePath(): string {
        return this.$route.path;
      },

      activeItem(): TypeListItem | undefined {
        return this.list.find((item) => {
          let isActive: boolean = false;

          const routePathWithoutSlash: string = this.routePath.replace('/', '');
          item.activeValues.forEach((itemPath) => {
            const itemActivePathWithoutSlash: string = itemPath.replace('/', '');
            if (itemActivePathWithoutSlash === routePathWithoutSlash) {
              isActive = true;
            }
          });

          return isActive;
        });
      },

      activeItemId(): symbol | undefined {
        return this.activeItem?.id ?? undefined;
      },

      activeItemName(): string {
        return this.activeItem?.name ?? '';
      },
    },

    methods: {
      NOOP,

      openActionSheet() {
        this.isOpenActionSheet = true;
        PageScroll.disable();
      },

      closeActionSheet() {
        this.isOpenActionSheet = false;
        PageScroll.enable();
      },

      onClickItem(value: string): void {
        this.$emit('change-active', value);
      },
    },
  });
</script>

<style lang="scss" scoped>
  $textColor: #5c5c5c;
  $hoverColor: #000000;
  $activeColor: #2962f9;
  $overlayBackground: black;
  $containerBackground: white;
  $borderColor: #e7e7e7;
  $titleColor: black;

  .desktop {
    display: none;

    @include tablet {
      display: block;
    }
  }

  .mobile {
    display: block;

    @include tablet {
      display: none;
    }
  }

  .item {
    //@include fontSmallTextBtn;
    position: relative;

    padding: 4px 12px;
    margin-right: 12px;

    border: none;

    color: $hoverColor;
    text-decoration: none;
    cursor: pointer;
    background-color: unset;
    outline: none;

    @include tablet {
      margin-right: 0;

      color: $textColor;
    }

    @include hover {
      color: $hoverColor;
    }

    &_active {
      color: $activeColor;

      @include hover {
        color: $activeColor;
        cursor: default;
      }
    }

    &:last-child {
      @include tablet {
        padding-right: 0;
      }
    }

    &:after {
      content: '';

      position: absolute;
      top: 4px;
      right: -12px;

      width: 12px;
      height: 12px;

      background-image: url(./images/arrow.svg);

      @include tablet {
        display: none;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    background-color: $overlayBackground;
    opacity: 0.8;
    z-index: 13;
  }

  .action-sheet {
    position: fixed;
    bottom: -81vh;
    left: 0;

    max-height: 80vh;
    width: 100%;
    padding-top: 48px;

    border-radius: 10px 10px 0 0;

    transition: all 300ms;
    background-color: $containerBackground;
    z-index: 14;

    &::before {
      content: '';

      position: absolute;
      top: 23px;
      left: calc(50% - 18px);

      width: 36px;
      height: 4px;
      border-radius: 2px;

      background-color: $borderColor;
    }

    &_open {
      bottom: 0;
    }
  }

  .action-sheet__title {
    //@include fontH4AccentNormalText;
    margin: 16px 0 12px 16px;
  }

  .action-sheet__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  .action-sheet__item {
    //@include fontNormalText;

    position: relative;

    box-sizing: border-box;
    width: 100%;
    padding: 16px;
    background: none;
    border: none;
    text-align: left;

    &_active:after {
      content: '';
      position: absolute;
      right: 20px;
      top: 20px;

      width: 16px;
      height: 16px;
      background-image: url('./images/ok.svg');
    }
  }
</style>
